<template>
  <div class="fw-panel-stats flex gap-5">
    <div v-if="loading || chartIcon">
      <fw-icon-loading v-if="loading" class="w-6 h-6" />
      <fw-icon-chart-box v-else-if="chartIcon" class="w-6 h-6 opacity-50" />
    </div>
    <div v-if="!loading">
      <fw-heading v-if="title" size="h4" muted class="mb-1">{{ title }}</fw-heading>
      <div
        :class="{
          'grid grid-flow-col-dense gap-5': type == 'grid',
          'flex gap-3': type == 'card',
          'text-white text-opacity-90': isDark,
          'text-gray-500': !isDark,
        }"
      >
        <template v-for="metric in stats">
          <div
            v-if="(hideIfEmpty && !emptyMetric(metric.value)) || !hideIfEmpty"
            :key="metric.$index"
            :class="[
              {
                'bg-gray-200 bg-opacity-50 p-5 rounded-xl flex flex-col gap-1': type == 'card',
                'text-center md:text-left flex flex-col': type == 'grid',
              },
              metric.customClass,
            ]"
          >
            <fw-heading :size="metric.featured ? 'h1' : 'h3'"
              >{{ metric.value }}
              <span v-if="metric.valueSuffix" class="text-md md:text-lg">{{ metric.valueSuffix }}</span>
            </fw-heading>
            <div v-if="metric.label && type == 'card'" class="text-sm">{{ metric.label }}</div>
            <div
              v-if="metric.label && type == 'grid'"
              class="pr-1 font-bold text-sm"
              :class="{
                'text-gray-400': !isDark,
                'text-white text-opacity-70': isDark,
              }"
            >
              {{ metric.label }}
            </div>
            <div
              v-if="metric.description"
              class="text-xs pr-2 font-medium"
              :class="{
                'text-gray-400': !isDark,
                'text-white text-opacity-50': isDark,
              }"
            >
              {{ metric.description }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stats: {
      type: Array,
      deep: true,
    },
    chartIcon: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideIfEmpty: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'grid',
    },
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emptyMetric(value) {
      if (!value || value === '' || value === '0') return true
      return false
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "updatedData": "Estes dados são atualizados várias vezes por dia."
  },
  "en": {
    "updatedData": "The data is updated several times per day."
  }
}
</i18n>
